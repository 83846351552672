import { Texture, IMG } from './texture.js'

// IMAGE SURFACE
const DEFAULTS = {

}
class ImageTexture extends Texture {
  constructor(src, opts) {
    super(IMG)
    Object.assign(this, DEFAULTS, opts || {})
    this.elem.src = src

    this._onLoad = this._onLoad.bind(this)

    this.elem.addEventListener("load", this._onLoad, true)
    this.elem.addEventListener("error", this._onError, true)

  }

  _onLoad(e) {
    this._onReady()
  }

  destroy() {
    this.onerror = null
    this.onload = null

    this.elem.removeEventListener("load", this._onLoad, true)
    this.elem.removeEventListener("error", this._onError, true)

    super.destroy()
  }
}

export {
  ImageTexture
}
