import { Texture, VIDEO } from './texture.js'

// VIDEO SURFACE
const DEFAULTS = {

}
class VideoTexture extends Texture {
  constructor(src, opts) {
    console.log("Video constructor")
    super(VIDEO)
    Object.assign(this, DEFAULTS, opts || {})
    this.elem.autoplay = false
    this.elem.muted = true
    this.elem.loop = false
    this.src = src
    this.playing = false


    this._onCanPlay = this._onCanPlay.bind(this)
    this._onCanPlayThrough = this._onCanPlayThrough.bind(this)
    this._onEnded = this._onEnded.bind(this)

    this.elem.addEventListener("canplay", this._onCanPlay, true)
    this.elem.addEventListener("canplaythrough", this._onCanPlayThrough, true)
    this.elem.addEventListener("ended", this._onEnded, true)
    this.elem.addEventListener("error", this._onError, true)
  }
  play() {
    console.log("play")
    if (!this.playing) {
      this.elem.play()
      this.playing = true
      if (this.onload) this.onload()
    }
  }

  set loop(value) {
    this.elem.loop = value
  }

  get loop() {
    return this.elem.loop
  }

  // Video Events
  _onCanPlay(e) {
    console.log("_onCanPlay")
    //this.play()
    //this._onReady()
  }

  _onCanPlayThrough(e) {
    console.log("_onCanPlayThrough")
    this.play()
    this._onReady()
  }

  _onEnded(e) {
    console.log("_onEnded")
    if (this.onComplete) this.onComplete()

  }

  destroy() {
    this.onerror = null
    this.oncomplete = null
    this.onload = null

    this.elem.removeEventListener("canplay", this.onCanplay, true)
    this.elem.removeEventListener("canplaythrough", this.onCanplayThrough, true)
    this.elem.removeEventListener("ended", this._onEnded, true)
    this.elem.removeEventListener("error", this._onError, true)

    if (!this.elem.paused) this.elem.pause()

    super.destroy()
  }
}

export {
  VideoTexture
}
