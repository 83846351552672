let pending = false
let ready = false
let error
let sw

function registerServiceWorker(src) {
  console.log("registerServiceWorker", src)
  if (pending || ready || error)
    return Promise.reject('ServiceWorker already registered') // one shot only
  pending = true
  return new Promise((resolve, reject) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', function(event){
        if (event.data && event.data.type === "ready") {
          if (!ready) resolve(sw) // prevent multiple
        }
      })
      navigator.serviceWorker.register(
        src,
        { scope: '/' }
      ).then((reg) => {
        sw = reg
        if (reg.active) resolve(sw) // direct resolve when active
      }).catch(reject)
    } else {
      reject("ServiceWorker not supported")
    }
  }).then(sw => {
    pending = false
    ready = true
    return sw
  }).catch((e) => {
    pending = false
    error = e
  })
}

export {
  registerServiceWorker
}
