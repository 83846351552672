import { makeUserAgentString } from '../lib/ua.js'

function getStats() {
  if (!navigator.storage || !navigator.storage.estimate) return Promise.reject("Storage not supported")
  return  navigator.storage.estimate().then((estimation) => {
    const usage = estimation.usage
    const quota = estimation.quota
    const percent = (!!estimation.quota) ? estimation.usage / estimation.quota * 100 : 0
    return { usage, quota, percent }
  })
}

class Splashscreen {
  constructor(selector) {
    console.log("Splashscreen constructor")
    this._elem = document.querySelector(selector)
    document.querySelector('.user-agent').innerHTML = makeUserAgentString()

    this._clickCount = 0

    this._clickTimeout
    this._updateTimeout
    this._updateInterval = 500
    this._showFor = 7500
    this._resetAfter = 700
    this._onClick = this._onClick.bind(this)
    this._onHide = this._onHide.bind(this)
    this._onUpdate = this._onUpdate.bind(this)
    this._onClickTimeout = this._onClickTimeout.bind(this)

    document.body.addEventListener("click", this._onClick)
    this.show()
  }

  updateDBStats() {
    getStats().then(stats => {
      this.storageUsage = stats.usage
      this.storageQuota = stats.quota
      this.storagePercent = stats.percent
    })
  }

  updateSize() {
    document.querySelector('.width').innerHTML = window.innerWidth
    document.querySelector('.height').innerHTML = window.innerHeight
  }

  _onClickTimeout() {
    this._clickCount = 0
  }

  _onHide() {
    if(this._hideTimeout) this._hideTimeout = clearTimeout(this._hideTimeout)
    this.hide()
  }

  _resetHideTimeout() {
    if(this._hideTimeout) this._hideTimeout = clearTimeout(this._hideTimeout)
    this._hideTimeout = setTimeout(this._onHide, this._showFor)
  }

  _onClick() {
    if (this._active) {
      this._resetHideTimeout()
    } else {
      if (this._clickTimeout) this._clickTimeout = clearTimeout(this._clickTimeout)
      this._clickCount++
      if (this._clickCount>4) {
        this._clickCount = 0
        this.show()
      } else {
        this._clickTimeout = setTimeout(this._onClickTimeout, this._resetAfter)
      }
    }
  }

  _onUpdate() {
    this._updateTimeout = null
    this.updateDBStats()
    this.updateSize()
    if (this._active) this._updateTimeout = setTimeout(this._onUpdate, this._updateInterval)
  }

  hide() {
    this._active = false
    this._elem.classList.remove("fadein")
    this._elem.classList.add("fadeout")
  }

  show() {
    console.log("Splashscreen show")
    this._active = true
    this._elem.classList.remove("fadeout")
    this._elem.classList.add("fadein")
    //this.domElement.classList.remove("fadeout")
    this.updateDBStats()
    this.updateSize()
    if (!this._updateTimeout) this._updateTimeout = setTimeout(this._onUpdate, this._updateInterval)
    this._resetHideTimeout()
  }

  set serviceWorkerStatus(str) {
    document.querySelector('.service-worker-status').innerHTML = str
  }

  set storageUsage(val) {
    document.querySelector('.storage-usage').innerHTML = (Math.round(val/(1024*1024)*10)/10).toFixed(1)
  }

  set storageQuota(val) {
    document.querySelector('.storage-quota').innerHTML = (Math.round(val/(1024*1024)*10)/10).toFixed(1)
  }

  set storagePercent(val) {
    document.querySelector('.storage-percent').innerHTML = val.toFixed(1)
  }

  set token(str) {
    document.querySelector('.token').innerHTML = str
  }

  set version(str) {
    document.querySelector('.version').innerHTML = str
  }

  monitor(client, player) {
    const cache = client.cache
    let surfaceElem = this._elem.querySelector('.surfaces')
    while (surfaceElem && surfaceElem.firstChild) {
      surfaceElem.removeChild(surfaceElem.firstChild)
    }
    for (var surfaceId in cache.booking) {
      const booking = cache.booking[surfaceId]
      const div = document.createElement('DIV')
      div.classList.add('surface')
      const idDiv = document.createElement('DIV')
      idDiv.innerHTML = "Surface: "+ booking.surfaceName
      div.appendChild(idDiv)
      if (booking.playlistId) {
        const playlist = cache.playlist[booking.playlistId]
        const playlistDiv = document.createElement('DIV')
        playlistDiv.innerHTML = "Playlist: "+ playlist.name
        div.appendChild(playlistDiv)
        for (let i = 0; i<playlist.mediaObjects.length; i++) {
          const mediaObject = playlist.mediaObjects[i]
          const asset = cache.asset[mediaObject.assetId]
          const mediaObjectDiv = document.createElement('DIV')
          mediaObjectDiv.innerHTML = asset.name
          const surface = player.surfaces[surfaceId]
          if (surface.index == i) {
            if (surface.animating) {
              mediaObjectDiv.classList.add("animating")
            } else {
              mediaObjectDiv.classList.add("playing")

            }
          }
          if (surface.error) {
            mediaObjectDiv.classList.add("error")
          }
          div.appendChild(mediaObjectDiv)
        }
      }

      surfaceElem.appendChild(div)
    }
  }

}

export {
  Splashscreen
}
