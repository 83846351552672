const DIV = 'DIV'
const IMG = 'IMG'
const VIDEO = 'VIDEO'


class DOMElement {
  constructor(type) {
    this.elem = document.createElement(type)
  }

  set style(obj) {
    Object.assign(this.elem.style, obj)
  }

  get style() {
    return this.elem.style
  }

  set parent(elem) {
    if (!elem && this.elem.parentNode) this.elem.parentNode.removeChild(this.elem)
    else if (elem) elem.appendChild(this.elem)
  }

  get parent() {
    return this.elem.parentNode
  }

  destroy() {
    this.parent = null
    this.elem = null
  }
}

export {
  DIV,
  IMG,
  VIDEO,
  DOMElement
}
