const BOOKING_INDEX = 0

function getBooking(state) {
  if (state && state.booking) {
    const bookings = Object.keys(state.booking)
    if (bookings.length > BOOKING_INDEX) {
      return state.booking[bookings[BOOKING_INDEX]]
    }
  }
  return null
}


function getPlaylist(state, playlistId) {
  if (state && state.playlist && state.playlist.hasOwnProperty(playlistId)) {
    return state.playlist[playlistId]
  }
  return null
}

function withinTimeConstraint(time, timeConstraint) {
  if (!timeConstraint) return true
  if (timeConstraint.startTime) {
    const startDate = new Date(timeConstraint.startTime)
    if (time < startDate.getTime()) return false
  }
  if (timeConstraint.stopTime) {
    const stopDate = new Date(timeConstraint.stopTime)
    if (time > stopDate.getTime()) return false
  }
  return true
}

function equalTimeConstraints(tc1, tc2) {
  return (tc1 === tc2) // eq when null or bin eq
    || ( // otherwise
      (tc1 && tc2) // both exist
      && ( // and
        tc1.startTime === tc2.startTime // start time matches
        && tc1.stopTime === tc2.stopTime // and stop time matches
      )
    ) // if non matches not equal
}


function mediaObjectWithinTime(time, mediaObjects) {
  let found = false
  if (mediaObjects) {
    for (let i = 0; i<mediaObjects.length; i++) {
      const mediaObject = mediaObjects[i]
      if (mediaObject && withinTimeConstraint(time, mediaObject.timeConstraint)) {
        found = true
        break
      }
    }
  }
  return found
}

function playlistWithinTime(time, playlist) {
  return playlist && withinTimeConstraint(time, playlist.timeConstraint)
}

function equalMediaObjects(mediaObjects1, mediaObjects2) {
  if (mediaObjects1 === mediaObjects2) return true // prob both non existent
  if (mediaObjects1.length != mediaObjects2.length) {
    return false
  }
  for (let i = 0; i<mediaObjects1.length; i++ ) {
    const mediaObject1 = mediaObjects1[i]
    const mediaObject2 = mediaObjects2[i]
    if (mediaObject1 && mediaObject2) { // if both exist
      if (mediaObject1.assetId !== mediaObject2.assetId) {
        return false
      }
      if (!equalTimeConstraints(
        mediaObject1.timeConstraint,
        mediaObject2.timeConstraint
      )) {
        return false
      }
      if (mediaObject1.duration != mediaObject2.duration) return false
    } else if (mediaObject1 || mediaObject2) { // one of both exists
      return false
    }
  }

  return true
}

function hasPlaylistChanged(state, newState) {
  const booking = getBooking(state)
  const newBooking = getBooking(newState)
  if (booking && newBooking) {
    if(booking.playlistId != newBooking.playlistId) return true
    const playlist = getPlaylist(state, booking.playlistId)
    const newPlaylist = getPlaylist(newState, newBooking.playlistId)
    if (playlist === newPlaylist) return false // both nonexistent
    if (!equalTimeConstraints(
      playlist.timeConstraint,
      newPlaylist.timeConstraint
    )) {
      return true
    }
    if (!equalMediaObjects(
      playlist.mediaObjects,
      newPlaylist.mediaObjects
    )) {
      //debuggerAppend("!equalMediaObjects", playlist.mediaObjects, newPlaylist.mediaObjects)
      return true
    }
  } else if (booking || newBooking) {
    //debuggerAppend("booking || newBooking", booking, newBooking)
    return true
  }
  return false

}


function hasStateChanged(state, newState) {
  if (!state && newState) return true // no state cached
  return hasPlaylistChanged(state, newState)
}

export {
  getBooking,
  getPlaylist,
  withinTimeConstraint,
  equalTimeConstraints,
  mediaObjectWithinTime,
  playlistWithinTime,
  equalMediaObjects,
  hasPlaylistChanged,
  hasStateChanged
}
