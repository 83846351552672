import { Rdy } from '../lib/rdy.js'
import { VIDEO, IMG } from '../player/texture.js'
import {
  hasStateChanged
} from './data-util.js'


function getHost() {
  return location.protocol + '//' + location.host
}


class EDGEWebserviceClient {
  constructor() {
    console.log("EDGEWebserviceClient constructor")
    this.pollInterval = 10000
    this._token = ""
    this._cache = this._makeCache()
    this._scene = this._makeScene()

    this._pollTimeout = null
    this._onStateChange = null
    this._poll = this._poll.bind(this)
  }

  _makeCache() {
    return {
      booking: {},
      playlist: {},
      asset: {},
      binaries: {},
    }
  }

  _getStatic() {
    return [
     '/css/main.css',
     '/bundle.js',
     '/assets/icon-192x192.png',
     this._appendToken('/')
   ].map((e) => {
     return getHost() + e
   })
  }

  _makeScene(cache) {
    const scene = {}
    if (!cache) return scene
    // state changed
    const bookingIds = Object.keys(cache.booking)
    bookingIds.forEach(bookingId => {
      const booking = cache.booking[bookingId]
      let result  = []
      let playlistId = booking.playlistId
      if (
        playlistId
        && cache.playlist.hasOwnProperty(playlistId)
      ) {
        const playlist = cache.playlist[playlistId]
        if (playlist.mediaObjects) {
          playlist.mediaObjects.forEach(mediaObject => {
            const assetId = mediaObject.assetId
            if (
              assetId
              && cache.asset.hasOwnProperty(assetId)
            ) {
              const asset = cache.asset[assetId]
              const item = {src: this._appendToken(asset.url) } //{ src: cache.binaries[assetId]}
              if (asset.raw && asset.raw.mimeType) {
                // get type from asset mimetype
                if(/^video\//gi.test(asset.raw.mimeType)){
                  item.type = VIDEO
                } else if(/^image\//gi.test(asset.raw.mimeType)){
                  item.type = IMG
                }
              }
              if (!item.type && asset.fileName) {
                // if mime somehow missing or invalid try extension
                if(/\.webm$|\.mp4$|\.h264$|\.h265$|\.ogg$|\.ogv$/gi.test(asset.fileName)) {
                  item.type = VIDEO
                } else if (
                  /\.jp(e)g$|\.jpe$|\.png$|\.gif$/gi.test(asset.fileName)
                ) {
                  item.type = IMG
                }
              }
              let from = 0
              let to = 0
              // flatten time constraints
              if (playlist.timeConstraint) {
                if (playlist.timeConstraint.startTime) {
                  from = new Date(playlist.timeConstraint.startTime).getTime()
                }
                if (playlist.timeConstraint.stopTime) {
                  to = new Date(playlist.timeConstraint.stopTime).getTime()
                }
              }
              if (mediaObject.timeConstraint) {
                if (mediaObject.timeConstraint.startTime) {
                  from = Math.max(
                    from,
                    new Date(mediaObject.timeConstraint.startTime).getTime()
                  )
                }
                if (mediaObject.timeConstraint.stopTime) {
                  to = Math.min(
                    to,
                    new Date(mediaObject.timeConstraint.stopTime).getTime()
                  )
                }
              }
              if (mediaObject.duration) item.duration = mediaObject.duration
              if (from) item.from = from
              if (to) item.to = to
              // skip all that dont match min req..
              if (item.type && item.src) result.push(item)
            }
          })
        }
      }
      //scene[bookingId].playlist = result
      scene[bookingId] = result
    })
    return scene
  }

  _appendToken(url) {
    if (!!this._token) url+="?token="+this._token
    return url
  }

  _fetch(url) {
    console.log("EDGEWebserviceClient _fetch")
    url = this._appendToken(url)
    this._requests.push(window.location.origin + url.replace(/\/$/, ""))
    return fetch(url, {redirect: "follow"})
  }


  _loadState() {
    console.log("EDGEWebserviceClient _loadState")
    return new Promise((resolve, reject) => {
      let result = this._makeCache()
      let rdy = new Rdy(() => { resolve(result) })
      // fetch bookings
      rdy.wait()
      this._fetch("/api/bookings").then(response => {
        // fail on status
        if (response.status >= 400 || response.status == 0)
          throw new Error(response.status)
        return response
      }).then(response => {
        return response.json()
      }).then(response => {
        // fail on error
        if (response.error) throw new Error(response.error)
        return response
      }).then((bookings) => {
        for (let bookingId in bookings) {
          const booking = bookings[bookingId]
          const playlistId = booking.playlistId
          result.booking[bookingId] = booking
          if (playlistId) {
            // fetch playlist
            rdy.wait()
            this._fetch("/api/playlist/"+playlistId).then(response => {
              // fail on status
              if (response.status >= 400 || response.status == 0)
                throw new Error(response.status)
              return response
            }).then(response => {
              return response.json()
            }).then(response => {
              // fail on error
              if (response.error) throw new Error(response.error)
              return response
            }).then((playlist) => {
              result.playlist[playlistId] = playlist
              for (let index = 0; index<playlist.mediaObjects.length; index++) {
                const assetId = playlist.mediaObjects[index].assetId
                if (assetId) {
                  // fetch asset
                  rdy.wait()
                  this._fetch("/api/asset/"+assetId).then(response => {
                    // fail on status
                    if (response.status >= 400 || response.status == 0)
                      throw new Error(response.status)
                    return response
                  }).then(response => {
                    return response.json()
                  }).then(response => {
                    // fail on error
                    if (response.error) throw new Error(response.error)
                    return response
                  }).then((asset) => {
                    asset.url = "/api/asset/"+assetId+"/raw"
                    result.asset[assetId] = asset
                    rdy.done() // fetch asset
                  }).catch(e =>{
                    // catch asset errors
                    console.warn(e)
                    rdy.done()
                  })
                }
              }
              rdy.done() // fetch playlis
            }).catch(e =>{
              // catch playlist errors
              console.warn(e)
              rdy.done()
            })
          }
        }
        rdy.done() // fetch booking
      }).catch(e =>{
        // catch booking errors
        console.warn(e)
        rdy.done()
      })
    })
  }

  _getMimeType(asset) {
    if (asset.raw && asset.raw.mimeType) return asset.raw.mimeType
    if ( /\.webm$/gi.test(asset.fileName)) return "video/webm"
    if (/\.mp4$/gi.test(asset.fileName)) return "video/mp4"
    if (/\.h264$/gi.test(asset.fileName)) return "video/h264"
    if (/\.h265$/gi.test(asset.fileName)) return "video/h265"
    if (/\.ogg$|\.ogv$/gi.test(asset.fileName)) return "video/ogg"
    if (/\.jp(e)g$|\.jpe$/gi.test(asset.fileName)) return "image/jpeg"
    if (/\.png$/gi.test(asset.fileName)) return "image/png"
    if (/\.gif$/gi.test(asset.fileName)) return "image/gif"
  }

  _loadBinaries(cache) {
    console.log("EDGEWebserviceClient _loadBinaries")
    return new Promise((resolve, reject) => {
      var rdy = new Rdy(() => { resolve() })
      for (let assetId in cache.asset) {
        var asset = cache.asset[assetId]
        if (asset) {
          // fetch binaries
          rdy.wait()
          this._fetch(asset.url).then(response => {
            // fail on status
            if (response.status >= 400 || response.status == 0)
              throw new Error(response.status)
            return response
          }).then((response) => {
            return response //return response.arrayBuffer()
          }).then((arrayBuffer) => {
            /*cache.binaries[assetId] = window.URL.createObjectURL(
              new Blob([arrayBuffer], {type: this._getMimeType(asset)})
            )*/
            rdy.done() // reading binary
          }).catch(e =>{
            // catch bin errors
            console.warn(e)
            rdy.done()
          })
        }
      }
    })
  }

  set onStateChange(callback) {
    this._onStateChange = callback
  }

  get onStateChange() {
    return this._onStateChange
  }


  set token(str) {
    this._token = str
  }

  get token() {
    return this._token
  }

  get loading() {
    return this._loading
  }

  get cache() {
    return this._cache
  }

  get scene() {
    return this._scene
  }

  _schedule() {
    this._pollTimeout = setTimeout(this._poll, this.pollInterval)
  }

  poll() {
    this._poll()
  }

  _reset() {
    this._requests = []
  }

  _garbageCollect(name, keep) {
    console.log("EDGEWebserviceClient _garbageCollect")
    caches.open(name).then(function(cache) {
      cache.keys().then(function(response) {
        response.forEach(function(element, index, array) {
          if (keep.indexOf(element.url) == -1) {
            cache.delete(element)
          }
        })
      })
    })
  }

  _poll() {
    console.log("EDGEWebserviceClient _poll")
    if (this._loading) return
    if (this._pollTimeout) this._pollTimeout = clearTimeout(this._pollTimeout)
    this._loading = true
    this._reset()
    this._loadState().then(cache => {
      if (hasStateChanged(this._cache, cache)) {
        this._loadBinaries(cache).then(bin => {
          this._cache  = cache
          this._scene = this._makeScene(this._cache)
          this._loading = false
          this._schedule()
          if (this._onStateChange) this._onStateChange()
          this._garbageCollect("cache_v2", [].concat(this._requests, this._getStatic()))
        })
      } else {
        this._loading = false
        this._schedule()
      }
    }).catch(e => {
      this._loading = false
      this._schedule()
    })
  }

  stopPoll() {
    if (this._pollTimeout) this._pollTimeout = clearTimeout(this._pollTimeout)
  }
}

export {
  EDGEWebserviceClient,
//  registerServiceWorker
}
