import { VideoTexture } from './video-texture.js'
import { ImageTexture } from './image-texture.js'
import { VIDEO, IMG } from './texture.js'
import { DOMElement, DIV} from './dom-element.js'
import { withinRange } from '../lib/math-util.js'

// IMAGE SURFACE
class Surface extends DOMElement{
  constructor(opts) {
    super(DIV)
    this.onChange
    this.next = this.next.bind(this)
    this.waitForCompletion = false
    this.timeout = 100000
    this._playlist = []
    this._index = -1
    this._idle = true
  }

  /*
    [
      {
        type: "video" or "image"
        src: urlOrBlob,
        duration: 0,
        from: timestamp uint
        to: timestamp uint

      }
    ]
  */
  set playlist(arr) {
    this._playlist = arr
    this._index = -1
    if (!this.waitForCompletion || this._idle) this.next()
  }

  get animating() {
    return !!this._nextTexture
  }

  get playlist() {
    return this._playlist
  }

  get index() {
    return this._index
  }

  next() {
    if (this._scheduleTimeout) this._scheduleTimeout = clearTimeout(this._scheduleTimeout)
    if (this._readyTimeout) this._readyTimeout = clearTimeout(this._readyTimeout)
    this._idle = false
    const time = Date.now()
    this._index = this.findNext(this._index, time)
    let schedule = Infinity
    if (this._index != -1) {
      const item = this._playlist[this._index]
      this._nextTexture = null
      switch (item.type) {
        case VIDEO:
          this._nextTexture = new VideoTexture(item.src)
          if (typeof item.duration === "number" && item.duration > 0) {
            schedule = item.duration
            this._nextTexture.loop = true
          }
          break
        case IMG:
          this._nextTexture = new ImageTexture(item.src)
          if (typeof item.duration === "number") {
            if ( item.duration > 0) schedule = item.duration
            else schedule = Infinity
          }
          break
      }
      if (this._nextTexture) {
        this._nextTexture.parent = this.elem

        this._readyTimeout = setTimeout(() => {
          item.error = 'timeout' // will be skipped next time
          this._nextTexture.destroy()
          this._nextTexture = null
          if(this.onChange) this.onChange()
          this.next()
        }, this.timeout)

        this._nextTexture.onReady = () => {
          if (this._readyTimeout) this._readyTimeout = clearTimeout(this._readyTimeout)
          if (this._nextTexture) this._nextTexture.reveal()
          if(this.onChange) this.onChange()
        }

        this._nextTexture.onReveal = () => {
          if (this._texture) this._texture.destroy()
          this._texture = this._nextTexture
          this._nextTexture = null
          if(this.onChange) this.onChange()
        }

        this._nextTexture.onError = (e) => {
          if (this._readyTimeout) this._readyTimeout = clearTimeout(this._readyTimeout)
          item.error = this._nextTexture.elem.error.message || 'error' // will be skipped next time
          this._nextTexture.destroy()
          this._nextTexture = null
          if(this.onChange) this.onChange()
          this.next()
        }

        if (schedule == Infinity && item.type === VIDEO) {
          this._nextTexture.onComplete = this.next // delegate schedule to vid
        }
      }
    } else {
      schedule = this.calcTimeout()
    }
    if (schedule != Infinity) {
      this._scheduleTimeout = setTimeout(this.next, schedule)
    } else {
      this._idle = true
    }
    if(this.onChange) this.onChange()
  }

  calcTimeout(time) {
    if (!time) time = Date.now()
    let result = Infinity
    this._playlist.forEach((item) => {
      if (!item.error && typeof item.from === "number" && item.from > time) {
        result = Math.min(result, item.from)
      }
    })
    return result
  }

  findNext(index, time) {
    if (!time) time = Date.now()
    let iterations = this._playlist.length
    let min = Infinity
    let found
    do {
      index = (++index)%this._playlist.length
      const item = this._playlist[index]
      if (!item.error) {
        found = withinRange(time, item.from, item.to)
      }
    } while (!found && --iterations)
    if (found) return index
    else return -1
  }

  destroy() {
    if (this._scheduleTimeout) this._scheduleTimeout = clearTimeout(this._scheduleTimeout)
    if (this._readyTimeout) this._readyTimeout = clearTimeout(this._readyTimeout)
    if (this._texture) this._texture.destroy()
    this.onChange = null
    this._texture = null
    if (this._nextTexture) this._nextTexture.destroy()
    this._nextTexture = null
    this.parent = null
    this.elem = null
    super.destroy()
  }
}

export {
  Surface
}
