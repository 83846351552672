import { randomString } from '../lib/random-string.js'

function gup( name, url ) {
  console.log("gup")
  if (!url) url = location.href
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]")
  var regexS = "[\\?&]"+name+"=([^&#]*)"
  var regex = new RegExp( regexS )
  var results = regex.exec( url )
  return results == null ? null : results[1]
}

function parse() {
  console.log("parse url parameters")
  const result = {debugMode: false, token:null, deviceId:null}
  //const url = new URL(window.location.href)

  result.debugMode = !!gup("debug") || false

  result.token = gup("token")
    || localStorage.getItem("edge-pwa-token")
    || randomString()
  //result.token = "edge-pwa-xs8agkgc2fmlv3ae"
  if (result.token) localStorage.setItem("edge-pwa-token", result.token)

  result.deviceId = localStorage.getItem("edge-pwa-device-id") || randomString()
  if (result.deviceId) localStorage.setItem("edge-pwa-device-id", result.deviceId)

  console.log("token from parser: "+result.token)
  return result
}

export {
  parse
}
