import { Player } from './player'
import { Splashscreen } from './splashscreen'
import { EDGEWebserviceClient } from './edge/webservice-client.js'

import { registerServiceWorker } from './lib/service-worker.js'

import { parse } from './edge/parameter-parser.js'

console.log("init")

const parameters = parse()

let splashscreen
window.addEventListener('load', () => {
  console.log("load")
  splashscreen = new Splashscreen('#splashscreen')
  splashscreen.version = "1.1.21"
  splashscreen.token = parameters.token
  splashscreen.serviceWorkerStatus = "initialization"
  registerServiceWorker('service-worker.js')
    .then(() => {
      splashscreen.serviceWorkerStatus = "ready"
    })
    .then(init)
    .catch(e => {
      console.warn(e)
      splashscreen.serviceWorkerStatus = e
    })
  splashscreen.updateDBStats()

})

function init() {
  console.log("init")
  let client = new EDGEWebserviceClient()
  client.token = parameters.token
  let player = new Player('#display')
  player.onChange = () => {
    splashscreen.monitor(client, player)
  }

  client.onStateChange = () => {
    const scene = client.scene
    player.scene = scene
  }
  client.poll()
}
