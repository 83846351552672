export class Rdy {
  constructor(fn) {
    this.count = 0
    this.fn = fn
  }

  wait() {
    this.count++
  }

  done() {
    if (!--this.count && typeof this.fn === "function" ) {
      this.fn()
      this.fn = null
    }
  }

}
