import { Surface } from './surface.js'

class Player {
  constructor(selector) {
    this._selector = selector || 'body'
    this.onChange
    this._onSurfaceChange = this._onSurfaceChange.bind(this)
    this._scene = {}
    this._surfaces = {}
  }

  _updateSurfaces() {
    console.log("update surfaces")

    const surfaceIds = Object.keys(this._scene)
    surfaceIds.forEach(surfaceId => {
      if (!this._surfaces.hasOwnProperty(surfaceId)) {
        this._surfaces[surfaceId] = new Surface()
        this._surfaces[surfaceId].onChange = this._onSurfaceChange
      }
      this._surfaces[surfaceId].parent = document.querySelector(this._selector)
      this._surfaces[surfaceId].playlist = this._scene[surfaceId]
    })
    // Garbage Collect
    Object.keys(this._surfaces).forEach((surfaceId) => {
      if (surfaceIds.indexOf(surfaceId) == -1) {
        this._surfaces[surfaceId].destroy()
        delete this._surfaces[surfaceId]
      }
    })
  }

  _onSurfaceChange() {
    if (this.onChange) this.onChange()
  }


  get surfaces() {
    return this._surfaces
  }

  get scene() {
    return this._scene
  }

  set scene(obj) {
    this._scene = obj
    this._updateSurfaces()
  }
}

export {
  Player
}
