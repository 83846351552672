import { DOMElement, IMG, VIDEO } from './dom-element.js'

const DEFAULTS = {
  fadeDuration: 600
}
class Texture extends DOMElement {
  constructor(type, opts) {
    super(type)
    Object.assign(this, DEFAULTS, opts || {})
    this._onAnimationFinish = this._onAnimationFinish.bind(this)
    this._onReady = this._onReady.bind(this)
    this._onError = this._onError.bind(this)
    this._revealTimeout
    this.style = {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      opacity: 0,
    }
    this._ready = false
    this._animation = null
  }

  _onAnimationFinish(e) {
    this.show()
    if (this._revealTimeout) this._revealTimeout = clearTimeout(this._revealTimeout)
    if (this._animation) {
      this._animation.onfinish = null
      this._animation.oncancel = null
      this._animation = null
    }
    if (this.onReveal) this.onReveal()
  }

  _onReady(e) {
    if (this._ready) return
    this._ready = true
    if (this.onReady) this.onReady()
  }

  _onError(e) {
    console.warn(e)
    if (this.onError) this.onError(e)
  }

  get useTransition() {
    return this.fadeDuration > 0
  }

  reveal() {
    if (this.useTransition && !this._animation) {
      this._animation = this.elem.animate(
        [{opacity:0}, {opacity:1}],
        {
          duration: this.fadeDuration
        }
      )
      this._animation.onfinish = this._onAnimationFinish
      this._animation.oncancel = this._onAnimationFinish
      this._revealTimeout = setTimeout(this._onAnimationFinish, this.fadeDuration+100)
    } else {
      this.show()
      this._onAnimationFinish()
    }
  }

  show() {
    this.style = {
      opacity: 1,
    }
  }

  hide() {
    this.style = {
      opacity: 0,
    }
  }

  set src(strOrBlob) {
    if (!strOrBlob) {
      if (this.elem.pause) this.elem.pause()
      this.elem.src = '' // for good sake, prob a myth
      this.elem.removeAttribute('src') // empty source
      if (this.elem.load) this.elem.load()
    } else {
      this.elem.src = strOrBlob
    }
  }

  get src() {
    return this.elem.src
  }

  destroy() {
    if (this._revealTimeout) this._revealTimeout = clearTimeout(this._revealTimeout)
    if (this._animation) {
      this._animation.onfinish = null
      this._animation.oncancel = null
      this._animation = null
    }
    this.src = null
    super.destroy()
  }
}

export {
  Texture,
  IMG,
  VIDEO
}
